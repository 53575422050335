/* website: 3432-infinitiquebec1
 * created at 2021-07-28 4:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/infiniti.scss";
@import "../utils/icons.scss";

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__infiniti-quebec-bweb{
        .listing-used-button-loading.sr-button-1{
            background: #F5273F !important;
            border-color: #F5273F !important;
            &:hover{
                color: #ffffff !important;
            }
        }
    }
}